import { Client } from "./types/client";

export enum ClientStatus {
    INACTIVE = 0,
    ACTIVE = 1,
}

export enum Regions {
    NORTE = 0,
    SUL = 1,
    LESTE = 2,
    NORDESTE = 3,
    OESTE = 4,
    NOROESTE = 5,
    CENTRO_SUL = 6,
    BARREIRO = 7,
    PAMPULHA = 8,
    VENDA_NOVA = 9,
    CENTRO = 10,
}

export enum TypesServices {
    PAID = 0,
    BONUS = 1
}

export enum Recurrent {
    SIM = 0,
    NAO = 1
}

export enum FormMode {
    Add = "add",
    Edit = "edit",
    View = "view",
}

export const defaultClient: Client = {
    id: '',
    type: 0,
    addressCep: '',
    addressCity: '',
    addressNeighborhood: '',
    address: '',
    addressNumber: '',
    email: '',
    phone: null,
    startedAsClient: new Date(),
    apartamentsCount: 0,
    totalExecutationTime: '00:00:00',
    createdBy: '',
    updatedAt: null,
    updatedBy: null,
    region: null,
    status: 1,
    name: '',
    latitude:null,
    longitude:null,
    createdAt: null,
  }