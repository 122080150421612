import { ActionContext } from 'vuex';
import { API_URL } from '@/config/api';
import { useNotification } from '@/composables/useNotification';
import { ClientEmployeeState } from '../types/state';
import { ClientEmployee, ClientEmployeeTransport } from '../types/client_employee';
import { DefaultResponse } from '@/types/response';
import axiosAuth from '@/config/axios';
import { ICreateClientEmployeeEvent, IEventClientSchedule, IEventClientScheduleDTO } from '../types/events';
import { defaultClient } from '@/modules/client/consts';
import { defaultEmployee } from '@/modules/employee/consts';

const { notifySuccess, notifyError } = useNotification();

const defaultClientEmployeeEvent: ICreateClientEmployeeEvent = {
  start: '',
  end: '',
  days: [],
  employeeIds: [],
  clientId: '',
  addressId: '',
}
const defaultClientEmployee: ClientEmployee = {
  id: '',
  client: defaultClient,
  employee: defaultEmployee,
  clientEmployeeTransports: []


}


const state: () => ClientEmployeeState = () => ({
  clientsEmployees: [],
  selectedClientEmployee: Object.assign({}, defaultClientEmployee),
  dialogCreateClientEmployee: false,
  dialogAddClientEmployeeTransport: false,
  filterCalendarAddress: null,
  events: [],
  pagination: {
    page: 1,
    itemsPerPage: 10,
    total: 0,
  },
  selectedClientEmployeeEvent: Object.assign({}, defaultClientEmployeeEvent),

});


const mutations = {
  SET_CLIENTS_EMPLOYEES(state: ClientEmployeeState, payload: { clientsEmployees: ClientEmployee[] }) {
    state.clientsEmployees = payload.clientsEmployees;
  },
  SET_CLIENT_EMPLOYEE_EVENTS(state: ClientEmployeeState, payload: { events: IEventClientSchedule[] }) {
    state.events = payload.events;
  },
  SHOW_DIALOG_CREATE_CLIENT_EMPLOYEE(state: ClientEmployeeState, payload: { show: boolean, start: string, end: string }) {
    state.dialogCreateClientEmployee = payload.show;
  },
  SHOW_DIALOG_ADD_CLIENT_EMPLOYEE_TRANSPORT(state: ClientEmployeeState, payload: { show: boolean }) {
    state.dialogAddClientEmployeeTransport = payload.show;
  },
  SET_SELECTED_CLIENT_EMPLOYEE(state: ClientEmployeeState, payload: { clientEmployee: ClientEmployee }) {
    state.selectedClientEmployee = payload.clientEmployee;
  },
  SET_CLIENT_EMPLOYEE_EVENT(state: ClientEmployeeState, payload: { event: ICreateClientEmployeeEvent }) {
    state.selectedClientEmployeeEvent = payload.event;
  },
  SET_FILTER_CALENDAR_ADDRESS(state: ClientEmployeeState, payload: { addressId: string }) {
    state.filterCalendarAddress = payload.addressId;
  },
};

const actions = {
  openTransportDialog({ commit, state }: ActionContext<ClientEmployeeState, any>, payload: { clientEmployeeSelected: ClientEmployee, showDialog: boolean }) {
    console.log(payload.clientEmployeeSelected)
    commit('SET_SELECTED_CLIENT_EMPLOYEE', { clientEmployee: payload.clientEmployeeSelected })
    console.log(state.selectedClientEmployee)
    commit('SHOW_DIALOG_ADD_CLIENT_EMPLOYEE_TRANSPORT', { show: payload.showDialog })
  },
  closeTransportDialog({ commit }: ActionContext<ClientEmployeeState, any>) {
    commit('SET_SELECTED_CLIENT_EMPLOYEE', { clientEmployee: Object.assign({}, defaultClientEmployee) })
    commit('SHOW_DIALOG_ADD_CLIENT_EMPLOYEE_TRANSPORT', { show: false })
  },
  async fetchClientsEmployees({ commit, state }: ActionContext<ClientEmployeeState, any>, payload: { clientId: string, employeeId: string }) {
    try {
      let url = `${API_URL}/client_employee`;
      if (payload.clientId) {
        url += `?clientId=${payload.clientId}`;
      }
      if (payload.employeeId) {
        url += `?employeeId=${payload.employeeId}`;
      }
      const response = await axiosAuth.get<DefaultResponse<ClientEmployee>>(`${url}`);
      console.log(response.data.data)
      commit('SET_CLIENTS_EMPLOYEES', { clientsEmployees: response.data.data });
    } catch (error) {
      notifyError('Erro ao buscar os dados');
      console.error('Erro ao buscar os dados:', error);
    }
  },
  async fetchClientEmployeeEvents({ commit }: ActionContext<ClientEmployeeState, any>, payload: { clientId: string, employeeId: string }) {
    try {
      let url = `${API_URL}/client_employee/events`;
      if (payload.employeeId) {
        url += `?employeeId=${payload.employeeId}`;
      }
      if (payload.clientId) {
        url += `?clientId=${payload.clientId}`;
      }
      const response = await axiosAuth.get<DefaultResponse<IEventClientScheduleDTO>>(`${url}`);
      const data = response.data.data.map((event) => {
        return {
          ...event,
          start: new Date(event.start),
          end: new Date(event.end)
        }
      })

      commit('SET_CLIENT_EMPLOYEE_EVENTS', { events: data });
    } catch (error) {
      notifyError('Erro ao buscar eventos');
    }
  },
  async showDialogCreateClientEmployee({ commit }: ActionContext<ClientEmployeeState, any>, payload: { show: boolean, day: number, start: string, end: string }) {
    const createClientEmployee = Object.assign({}, defaultClientEmployeeEvent);
    createClientEmployee.start = payload.start;
    createClientEmployee.end = payload.end;
    createClientEmployee.days = [payload.day];
    commit('SHOW_DIALOG_CREATE_CLIENT_EMPLOYEE', payload);
    commit('SET_CLIENT_EMPLOYEE_EVENT', { event: createClientEmployee });
  },

  async addTransportToClientEmployee(
    { state,dispatch }: ActionContext<ClientEmployeeState, any>,
    payload: ClientEmployeeTransport
  ) {
    console.log(state.selectedClientEmployee.id)
    try {
      const url = `${API_URL}/client_employee/${state.selectedClientEmployee.id}/transport`;

      const transportData = {
        bus: payload.bus,
        type: payload.type,
        value: payload.value,
      };

      await axiosAuth.post<DefaultResponse<ClientEmployeeTransport>>(url, transportData);

      notifySuccess('Transporte adicionado com sucesso');

    } catch (error) {
      notifyError('Erro ao adicionar transporte');
      console.error('Erro ao adicionar transporte:', error);
    }
  }
};



export default {
  namespaced: true,
  state,
  mutations,
  actions
};
