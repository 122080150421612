import { RouteRecordRaw } from 'vue-router';

const serviceRoutes : Array<RouteRecordRaw> = [
    {
        path: '/services',
        name: 'services',
        component: () => import( './views/ServicesView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    
]

export default serviceRoutes;