import { ActionContext } from 'vuex';
import { useNotification } from '@/composables/useNotification';
import { Employee, IFilterByClient } from '../types/employee';
import { EmployeeState } from './state';
import { FormMode } from '@/consts/form';
import axiosAuth from '@/config/axios';
import { API_URL } from '@/config/api';
import { DefaultResponse } from '@/types/response';
import router from '@/router';
import { defaultEmployee } from '../consts';


const { notifySuccess, notifyError } = useNotification();

const state: () => EmployeeState = () => ({
  employees: [],
  filteredEmployees: [],
  filterEmployees: {
    doc: '',
    name: '',
  },
  formMode: FormMode.ADD,
  pagination: {
    page: 1,
    itemsPerPage: 10,
    total: 0,
  },
  selectedEmployee: defaultEmployee,
  showFormDialog: false,
});


const mutations = {
  SET_EMPLOYEES(state: EmployeeState, employees: Employee[]) {
    state.employees = employees;
    state.filteredEmployees = employees;
  },
  SET_FILTERED_EMPLOYEES(state: EmployeeState, filteredEmployees: Employee[]) {
    state.filteredEmployees = filteredEmployees;
  },
  SHOW_FORM_DIALOG(state: EmployeeState, payload: { show: boolean }) {
    state.showFormDialog = payload.show;

  },
  SET_SELECTED_EMPLOYEE(state: EmployeeState, payload: { employee: Employee }) {
    state.selectedEmployee = Object.assign({}, payload.employee);
  },
  SET_FORM_MODE(state: EmployeeState, payload: { mode: FormMode }) {
    state.formMode = payload.mode;
  },
  SET_TOTAL_ITEMS(state: EmployeeState, payload: { total: number }) {
    state.pagination.total = payload.total;
  }
};

const actions = {
  openCreateDialog({ commit }: ActionContext<EmployeeState, any>) {
    commit('SET_FORM_MODE', { mode: FormMode.ADD });
    commit('SET_SELECTED_EMPLOYEE', { employee: defaultEmployee });
    commit('SHOW_FORM_DIALOG', { show: true });
  },
  openEditDialog({ commit }: ActionContext<EmployeeState, any>, payload: { employee: Employee }) {
    commit('SET_FORM_MODE', { mode: FormMode.EDIT });
    commit('SET_SELECTED_EMPLOYEE', payload.employee);
    commit('SHOW_FORM_DIALOG', { show: true });
  },
  openScreenDetails({ commit }: ActionContext<EmployeeState, any>,  payload: { employee: Employee }) {
    router.push({ name: 'employeeDetails', params: { id: payload.employee.id } });
    commit('SET_SELECTED_EMPLOYEE', payload.employee);
  },
  openViewDialog({ commit }: ActionContext<EmployeeState, any>,  payload: { employee: Employee }) {
    commit('SET_FORM_MODE', { mode: FormMode.VIEW });
    commit('SET_SELECTED_EMPLOYEE', payload);
    commit('SHOW_FORM_DIALOG', { show: true });
  },
  async createEmployee({ dispatch, state }: ActionContext<EmployeeState, any>) {
    try {
      await axiosAuth.post(`${API_URL}/employees`, state.selectedEmployee);
      notifySuccess('Colaborador criado com sucesso');
      dispatch('fetchEmployees');
    } catch (error) {
      notifyError('Erro ao criar colaborador');
    }
  },
  async fetchEmployees({ commit, state }: ActionContext<EmployeeState, any>, payload: { filterByClient?: IFilterByClient } = {}) {
    try {
      const params: any = {};

      if (state.filterEmployees.doc) {
        params.doc = state.filterEmployees.doc;
      }
      if (state.filterEmployees.name) {
        params.name = state.filterEmployees.name;
      }
      if (payload.filterByClient) {
        console.log('to aqui', payload.filterByClient.address)
        params.latitude = payload.filterByClient.address.latitude;
        params.longitude = payload.filterByClient.address.longitude;
        params.minFreeHours = payload.filterByClient.minFreeHours;
        params.startHour = payload.filterByClient.startHour;
        params.finishHour = payload.filterByClient.finishHour;
        params.days = payload.filterByClient.days.join(',');
      }
      const response = await axiosAuth.get<DefaultResponse<Employee>>(`${API_URL}/employees`, { params });
      commit('SET_EMPLOYEES', response.data.data);
    } catch (error) {
      notifyError('Erro ao buscar colaboradores');
    }
  },
  async fetchEmployeeById({ commit }: ActionContext<EmployeeState, any>, payload: { id: string }) {
    try {
      console.log("Param employee get: ", payload)
      const response = await axiosAuth.get<DefaultResponse<Employee>>(`${API_URL}/employees/${payload.id}`);
      commit('SET_SELECTED_EMPLOYEE', { employee: response.data.data });
    } catch(error) {
      notifyError('Erro ao buscar colaborador');
    }
  }
};



export default {
  namespaced: true,
  state,
  mutations,
  actions
};
