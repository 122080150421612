<template>
    <div>
    
      <simple-menu
        v-if="!menu.menus || menu.menus.length === 0"
        :menu="menu"
      ></simple-menu>
  
      <compound-menu v-else
        :menu="menu"
      ></compound-menu>
    </div>
  </template>
  
  <script>
  import CompoundMenu from "./CompoundMenu";
  import SimpleMenu from "./SimpleMenu";
  
  export default {
    name: "MenuItem",
    components: {
      "compound-menu": CompoundMenu,
      "simple-menu": SimpleMenu,
    },
    data: () => ({
      mini: true,
      menus: [],
    }),
    methods: {},
    props: ["menu"],
  };
  </script>
  
  <style>
  </style>