import { FormMode } from "@/consts/form";
import { StockItemsState } from "../types/state";
import { ActionContext } from "vuex";
import axiosAuth from "@/config/axios";
import { DefaultResponse } from "@/types/response";
import { endPoints } from "@/config/api";
import { useNotification } from "@/composables/useNotification";
import { StockItem } from "../types/stock_items";

const { notifySuccess, notifyError } = useNotification();

const defaultStockItem: StockItem = {
    description: '',
    unit: 'UN',
    createdAt: new Date(),
    createdBy: '',
    updatedAt: null,
    updatedBy: null
};

const state: () => StockItemsState = () => ({
    filterStockItem: {
        description: '',
        baseValue: 0
    },
    stock_items: [],
    pagination: {
        page: 1,
        itemsPerPage: 10,
        total: 0
    },
    selectedStockItem: Object.assign({}, defaultStockItem),
    formMode: FormMode.ADD,
    showFormDialog: false,
    showDeleteDialog: false,
});

const mutations = {
    SHOW_DELETE_DIALOG(state: StockItemsState, payload: { show: boolean }) {
        state.showDeleteDialog = payload.show;
    },
    SET_STOCK_ITEMS(state: StockItemsState, payload: { stock_items: StockItem[] }) {
        console.log("Serviços: ", payload.stock_items);
        state.stock_items = payload.stock_items;
    },
    SHOW_FORM_DIALOG(state: StockItemsState, payload: { show: boolean }) {
        console.log('to aqui', payload)
        state.showFormDialog = payload.show;
    },
    SET_SELECTED_SERVICE(state: StockItemsState, payload: { stock_items: StockItem }) {
        state.selectedStockItem = Object.assign({}, payload.stock_items);
    },
    SET_FORM_MODE(state: StockItemsState, payload: { mode: FormMode }) {
        state.formMode = payload.mode;
    },
};

const actions = {
    openCreateDialog({ commit }: ActionContext<StockItemsState, any>) {
        commit('SET_FORM_MODE', { mode: FormMode.ADD });
        commit('SET_SELECTED_SERVICE', { stock_items: defaultStockItem });
        commit('SHOW_FORM_DIALOG', { show: true });
      },
    openEditDialog({ commit }: ActionContext<StockItemsState, any>, payload: { stock_items: StockItem }) {
        commit('SET_FORM_MODE', { mode: FormMode.EDIT });
        commit('SET_SELECTED_SERVICE', payload);
        commit('SHOW_FORM_DIALOG', { show: true });
    },
    openViewDialog({ commit }: ActionContext<StockItemsState, any>,  payload: { stock_items: StockItem }) {
        commit('SET_FORM_MODE', { mode: FormMode.VIEW });
        commit('SET_SELECTED_SERVICE', payload);
        commit('SHOW_FORM_DIALOG', { show: true });
    },
    openDeleteDialog({ commit }: ActionContext<StockItemsState, any>, payload: { stock_items: StockItem }) {
        commit('SHOW_DELETE_DIALOG', { show: true });
        commit('SET_SELECTED_SERVICE', { stock_items: payload.stock_items });
    },
    closeDialog({ commit }: ActionContext<StockItemsState, any>) {
        commit('SHOW_FORM_DIALOG', { show: false });
    },
    async fetchStockItems({ commit, state }: ActionContext<StockItemsState, any>, ) {
        try {
            const params: any = {};
        
            if (state.filterStockItem.description) {
                params.description = state.filterStockItem.description;
            }

            if (state.filterStockItem.baseValue) {
                params.baseValue = state.filterStockItem.baseValue;
            }
            
            const response = await axiosAuth.get<DefaultResponse<StockItem>>(`${endPoints.stock_items}`, { params });
            commit('SET_STOCK_ITEMS', { stock_items: response.data.data });
        } catch (error) {
            notifyError('Erro ao buscar itens do estoque');
        }
    },
    async createStockItem({ commit, dispatch, state }: ActionContext<StockItemsState, any>) {
        try {
          await axiosAuth.post(`${endPoints.stock_items}`, state.selectedStockItem);
          notifySuccess('Serviço criado com sucesso');
          commit('SHOW_FORM_DIALOG', { show: false });
          dispatch('fetchStockItems');
        } catch (error) {
          notifyError('Erro ao criar item do estoque');
        }
    },
    async updateStockItem({ commit, dispatch, state }: ActionContext<StockItemsState, any>) {
        const { id } = state.selectedStockItem;
        try {
          await axiosAuth.put(`${endPoints.stock_items}/${id}`, state.selectedStockItem);
          notifySuccess('Serviço atualizado com sucesso');
          commit('SHOW_FORM_DIALOG', { show: false });
          dispatch('fetchStockItems');
        } catch (error) {
          notifyError('Erro');
          console.error('Erro ao atualizar item do estoque:', error);
        }
    },
    async deleteStockItem({ commit, dispatch }: ActionContext<StockItemsState, any>, payload: {stock_items: StockItem}) {
        try {
          await axiosAuth.delete(`${endPoints.stock_items}/${payload.stock_items.id}`);
          notifySuccess('Serviço deletado com sucesso');
          commit('SHOW_DELETE_DIALOG', { show: false });
          dispatch('fetchStockItems');
        } catch (error) {
          notifyError('Erro');
          console.error('Erro ao deletar item do estoque:', error);
        }
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};