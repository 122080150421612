import { RouteRecordRaw } from 'vue-router';

const dashboardRoutes : Array<RouteRecordRaw> = [
    {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('./views/HomeView.vue'),
        meta: {
            requiresAuth: true
        }
    }
]

export default dashboardRoutes;