import { FormMode } from "@/consts/form";
import { ServicesState } from "../types/state";
import supabase from "@/plugins/supabase";
import { Service } from "../types/service";
import { ActionContext } from "vuex";
import axiosAuth from "@/config/axios";
import { DefaultResponse } from "@/types/response";
import { API_URL, endPoints } from "@/config/api";
import { useNotification } from "@/composables/useNotification";

const { notifySuccess, notifyError } = useNotification();

const defaultService: Service = {
    description: '',
    baseValue: 0,
    createdAt: new Date(),
    createdBy: '',
    updatedAt: null,
    updatedBy: null
};

const state: () => ServicesState = () => ({
    filterService: {
        description: '',
        baseValue: 0
    },
    services: [],
    pagination: {
        page: 1,
        itemsPerPage: 10,
        total: 0
    },
    selectedService: Object.assign({}, defaultService),
    formMode: FormMode.ADD,
    showFormDialog: false,
    showDeleteDialog: false,
});

const mutations = {
    SET_DELETE_DIALOG(state: ServicesState, payload: { show: boolean }) {
        state.showDeleteDialog = payload.show;
    },
    SET_SERVICES(state: ServicesState, payload: { services: Service[] }) {
        console.log("Serviços: ", payload.services);
        state.services = payload.services;
    },
    SHOW_FORM_DIALOG(state: ServicesState, payload: { show: boolean }) {
        console.log('to aqui', payload)
        state.showFormDialog = payload.show;
    },
    SET_SELECTED_SERVICE(state: ServicesState, payload: { service: Service }) {
        state.selectedService = Object.assign({}, payload.service);
    },
    SET_FORM_MODE(state: ServicesState, payload: { mode: FormMode }) {
        state.formMode = payload.mode;
    },
};

const actions = {
    openCreateDialog({ commit }: ActionContext<ServicesState, any>) {
        commit('SET_FORM_MODE', { mode: FormMode.ADD });
        commit('SET_SELECTED_SERVICE', { service: defaultService });
        commit('SHOW_FORM_DIALOG', { show: true });
      },
    openEditDialog({ commit }: ActionContext<ServicesState, any>, payload: { service: Service }) {
        commit('SET_FORM_MODE', { mode: FormMode.EDIT });
        commit('SET_SELECTED_SERVICE', payload);
        commit('SHOW_FORM_DIALOG', { show: true });
    },
    openViewDialog({ commit }: ActionContext<ServicesState, any>,  payload: { service: Service }) {
        commit('SET_FORM_MODE', { mode: FormMode.VIEW });
        commit('SET_SELECTED_SERVICE', payload);
        commit('SHOW_FORM_DIALOG', { show: true });
    },
    openDeleteDialog({ commit }: ActionContext<ServicesState, any>, payload: { service: Service }) {
        commit('SHOW_DELETE_DIALOG', { show: true });
        commit('SET_SELECTED_SERVICE', { service: payload.service });
    },
    async fetchServices({ commit, state }: ActionContext<ServicesState, any>, ) {
        try {
            const params: any = {};
        
            if (state.filterService.description) {
                params.description = state.filterService.description;
            }

            if (state.filterService.baseValue) {
                params.baseValue = state.filterService.baseValue;
            }
            
            const response = await axiosAuth.get<DefaultResponse<Service>>(`${endPoints.services}`, { params });
            commit('SET_SERVICES', { services: response.data.data });
        } catch (error) {
            notifyError('Erro ao buscar serviços');
        }
    },
    async createService({ commit, dispatch, state }: ActionContext<ServicesState, any>) {
        try {
          await axiosAuth.post(`${endPoints.services}`, state.selectedService);
          notifySuccess('Serviço criado com sucesso');
          commit('SHOW_FORM_DIALOG', { show: false });
          dispatch('fetchServices');
        } catch (error) {
          notifyError('Erro ao criar serviço');
        }
    },
    async updateService({ commit, dispatch, state }: ActionContext<ServicesState, any>) {
        const { id } = state.selectedService;
        try {
          await axiosAuth.put(`${endPoints.services}/${id}`, state.selectedService);
          notifySuccess('Serviço atualizado com sucesso');
          commit('SHOW_FORM_DIALOG', { show: false });
          dispatch('fetchServices');
        } catch (error) {
          notifyError('Erro');
          console.error('Erro ao atualizar serviço:', error);
        }
    },
    async deleteService({ commit, dispatch }: ActionContext<ServicesState, any>, payload: {service: Service}) {
        try {
          await axiosAuth.delete(`${endPoints.services}/${payload.service.id}`);
          notifySuccess('Serviço deletado com sucesso');
          commit('SHOW_DELETE_DIALOG', { show: false });
          dispatch('fetchServices');
        } catch (error) {
          notifyError('Erro');
          console.error('Erro ao deletar serviço:', error);
        }
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};