// src/plugins/vuetify.ts
import { createVuetify } from 'vuetify';
import 'vuetify/styles'; // Importa os estilos do Vuetify
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import '@mdi/font/css/materialdesignicons.css'
import { VCalendar } from '@/libs/vuetify/lib/labs/components.mjs';
import { VTimePicker } from 'vuetify/labs/VTimePicker'
import { VDateInput } from 'vuetify/labs/VDateInput'

export default createVuetify({
    components: {
        VCalendar,
        VTimePicker,
        VDateInput,
        
        ...components
    },
    locale: {
        locale: 'pt-BR',
    },
    directives,
    theme: {
        defaultTheme: 'dark',
        themes: {
            light: {
                dark: false,
                colors: {
                    background: '#ffffff',
                    primary: '#1976D2',
                    primaryButton: '#0061c8',
                },
            },
            dark: {
                dark: true,
                colors: {
                    background: '#121212',
                    primary: '#0061c8',
                    primaryButton: '#0061c8',
                },
            },
        },
    },
    icons: {
        defaultSet: 'mdi',
    },
});
