import { RouteRecordRaw } from 'vue-router';

const stockItemsRoutes : Array<RouteRecordRaw> = [
    {
        path: '/stock_control',
        name: 'stock_control',
        component: () => import( './views/StockControlView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    
]

export default stockItemsRoutes;