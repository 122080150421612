export const SUPABASE_API_URL = process.env?.VUE_APP_SUPABASE_URL || '';

export const API_URL = process.env?.VUE_APP_API_URL || '';

export const endPoints = {
    services: API_URL + '/services',
    employees: API_URL + '/employees',
    clients: API_URL + '/clients',
    stock_items: API_URL + '/stock/items',
    stock_control: API_URL + '/stock/control',
}