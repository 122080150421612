import { ActionContext } from 'vuex';
import axiosAuth from '@/config/axios';
import { API_URL } from '@/config/api';
import { Client, ClientAddresses, ClientServices, FilterParams } from '../types/client';
import { useNotification } from '@/composables/useNotification';
import { FormMode, defaultClient } from '../consts';
import { ClientState } from '../types/state';


const { notifySuccess, notifyError } = useNotification();

const state: () => ClientState = () => ({
  addresses: [],
  clients: [],
  services: [],
  filteredClients: [],
  formMode: FormMode.Add,
  isCreateDialogOpen: false,
  pagination: {
    page: 1,
    itemsPerPage: 10,
    total: 0,
  },
  paginationServices: {
    page: 1,
    itemsPerPage: 10,
    total: 0,
  },
  selectedClient: defaultClient,
  selectedClientEmployees: [],
  selectedAddress: {
    id: "",
    addressCep: "",
    addressCity: "",
    addressNeighborhood: "",
    address: "",
    addressNumber: "",
    addressState: "",
    clientId: "",
    latitude: 0,
    longitude: 0
  },
  addressSelectedFormated: ""

});


const mutations = {
  SET_CLIENTS(state: ClientState, clients: Client[]) {
    state.clients = clients;
    state.filteredClients = clients;
  },
  SET_FILTERED_CLIENTS(state: ClientState, filteredClients: Client[]) {
    state.filteredClients = filteredClients;
  },
  SET_CREATE_DIALOG_OPEN(state: ClientState, isOpen: boolean) {
    state.isCreateDialogOpen = isOpen;
  },
  SET_SELECTED_CLIENT(state: ClientState, client: Client) {
    state.selectedClient = Object.assign({}, client);
  },
  SET_FORM_MODE(state: ClientState, mode: FormMode) {
    state.formMode = mode;
  },
  SET_ADDRESSES(state: ClientState, addresses: ClientAddresses[]) {
    state.addresses = addresses;
  },
  SET_SERVICES(state: ClientState, addresses: ClientServices[]) {
    state.services = addresses;
  },
  SET_TOTAL_ITEMS(state: ClientState, payload: { total: number }) {
    state.pagination.total = payload.total;
  },
  SET_CLIENT_ADDRESS_SELECTED(state: ClientState, payload: { addressSelected: ClientAddresses }) {
    state.selectedAddress = Object.assign({}, payload.addressSelected)
  },
  SET_ADDRESS_FORMATED(state: ClientState, payload: { addressFormated: string }) {
    state.addressSelectedFormated = payload.addressFormated
  },

};

const actions = {
  setClientAddresSelectedAndFormated({ commit, state }: ActionContext<ClientState, any>, payload: { idAddress: string }) {
    const addressSelected = state.addresses.find((address) => address.id === payload.idAddress)
    const addressFormated = `${addressSelected?.address}, ${addressSelected?.addressNeighborhood}, ${addressSelected?.addressNumber} - ${addressSelected?.addressCity}`
    commit("SET_CLIENT_ADDRESS_SELECTED", { addressSelected: addressSelected })
    commit("SET_ADDRESS_FORMATED", { addressFormated: addressFormated })
  },
  async fetchClientsById({ commit }: ActionContext<ClientState, any>, payload: { clientId: string }) {
    try {
      const response = await axiosAuth.get<Client>(`${API_URL}/clients/${payload.clientId}`);
      console.log('Client:', response);
      commit('SET_SELECTED_CLIENT', response.data);
    } catch (error) {
      notifyError('Erro ao buscar cliente');
      console.error('Erro ao buscar cliente:', error);
    }
  },
  async fetchClients({ commit }: ActionContext<ClientState, any>) {
    try {
      const response = await axiosAuth.get<Client[]>(`${API_URL}/clients`);

      commit('SET_CLIENTS', response.data);
    } catch (error) {
      notifyError('Erro ao buscar clientes');
      console.error('Erro ao buscar clientes:', error);
    }
  },
  filterClients({ commit, state }: ActionContext<ClientState, any>, filterParams: FilterParams) {
    const filtered = state.clients.filter(client => {
      let matches = true;
      if (filterParams.name) {
        matches = client.name.toLowerCase().includes(filterParams.name.toLowerCase());
      }
      if (filterParams.email) {
        matches = matches && client.email.toLowerCase().includes(filterParams.email.toLowerCase());
      }
      return matches;
    });
    commit('SET_FILTERED_CLIENTS', filtered);
  },
  async createClient({ dispatch, state }: ActionContext<ClientState, any>) {
    const { id, createdAt, updatedAt, ...clientToSave } = state.selectedClient;

    console.log('Cliente a ser criado:', clientToSave);

    try {
      await axiosAuth.post(`${API_URL}/clients`, clientToSave);
      notifySuccess('Cliente criado com sucesso');
      dispatch('fetchClients');
    } catch (error) {
      console.error('Erro ao criar cliente:', error);
      notifyError('Erro');
    }
  },
  async updateClient({ dispatch, state }: ActionContext<ClientState, any>) {
    const { id, createdAt, updatedAt, ...clientToUpdate } = state.selectedClient;
    try {
      await axiosAuth.put(`${API_URL}/clients/${id}`, clientToUpdate);
      notifySuccess('Cliente atualizado com sucesso');
      dispatch('fetchClients');
    } catch (error) {
      notifyError('Erro');
      console.error('Erro ao atualizar cliente:', error);
    }
  },
  async changeStatusClient({ dispatch }: ActionContext<ClientState, any>, id: string) {
    try {
      await axiosAuth.put(`${API_URL}/clients/${id}/status`);
      notifySuccess('Status do cliente alterado com sucesso');
      dispatch('fetchClients');
    } catch (error) {
      notifyError('Erro');
      console.error('Erro ao alterar status do cliente:', error);
    }
  },
  async deleteClient({ dispatch }: ActionContext<ClientState, any>, id: string) {
    try {
      await axiosAuth.delete(`${API_URL}/clients/${id}`);
      notifySuccess('Cliente deletado com sucesso');
      dispatch('fetchClients');
    } catch (error) {
      notifyError('Erro');
      console.error('Erro ao deletar cliente:', error);
    }
  },
  openCreateDialog({ commit }: ActionContext<ClientState, any>) {
    commit('SET_FORM_MODE', FormMode.Add);
    commit('SET_SELECTED_CLIENT', defaultClient);
    commit('SET_CREATE_DIALOG_OPEN', true);
  },
  openEditDialog({ commit }: ActionContext<ClientState, any>, client: Client) {
    commit('SET_FORM_MODE', FormMode.Edit);
    commit('SET_SELECTED_CLIENT', client);
    commit('SET_CREATE_DIALOG_OPEN', true);
  },
  async openScreenDetails({ commit, dispatch }: ActionContext<ClientState, any>, payload: { clientId: string }) {
    try {
      dispatch('fetchClientsById', { clientId: payload.clientId });
      dispatch('getClientAddresses', { clientId: payload.clientId });
      dispatch('getClientServices', { clientId: payload.clientId });
      dispatch('services/fetchServices', {}, { root: true });
      dispatch('client_employee/fetchClientsEmployees', { clientId: payload.clientId }, { root: true });
    } catch (error) {
      notifyError('Erro ao buscar informações do cliente');
      console.error('Erro ao buscar informações do cliente:', error);
    }
  },

  async getClientServices({ commit }: ActionContext<ClientState, any>, payload: { clientId: string }) {
    try {
      const response = await axiosAuth.get<ClientServices[]>(`${API_URL}/client_services/${payload.clientId}`);
      commit('SET_SERVICES', response.data);
      console.log(response.data)

    } catch (error) {
      notifyError('Erro ao buscar serviços');
      console.error('Erro ao buscar serviços:', error);
    }
  },
  async getClientAddresses({ commit }: ActionContext<ClientState, any>, payload: { clientId: string }) {
    try {
      const response = await axiosAuth.get<ClientAddresses[]>(`${API_URL}/client_addresses/${payload.clientId}`);
      commit('SET_ADDRESSES', response.data);

    } catch (error) {
      notifyError('Erro ao buscar endereços');
      console.error('Erro ao buscar endereços:', error);
    }
  },
  openViewDialog({ commit }: ActionContext<ClientState, any>, client: Client) {
    commit('SET_FORM_MODE', FormMode.View);
    commit('SET_SELECTED_CLIENT', client);
    commit('SET_CREATE_DIALOG_OPEN', true);
  },
  closeCreateDialog({ commit }: ActionContext<ClientState, any>) {
    commit('SET_CREATE_DIALOG_OPEN', false);
  },
};



export default {
  namespaced: true,
  state,
  mutations,
  actions
};
