import { ISession } from "@/modules/auth/types/auth.types";
import axios from "axios";

const axiosAuth = axios.create();
axiosAuth.interceptors.request.use(
  (config) => {
    const session = localStorage.getItem('session-jl');
    if (session) {
        const sessionParsed : {session: ISession } = JSON.parse(session);
        const token = sessionParsed.session.access_token;
        config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);

export default axiosAuth;