import { ActionContext } from 'vuex';
import supabase from "@/plugins/supabase"
import { AuthState, ILogin, IProfile, ISession, IUser } from '../types/auth.types';
import router from '@/router'
import { useNotification } from '@/composables/useNotification';

const { notifySuccess, notifyError } = useNotification();

const LOCAL_STORAGE_ITEM = 'session-jl'

const defaultSession : ISession = {
    access_token: '',
    expires_at: 0,
    expires_in: 0,
    refresh_token: '',
    token_type: '',
    stay_connected: false
}
const defaultProfile : IProfile = {
    created_at: '',
    created_by: '',
    doc: '',
    name: '',
    role_id: '',
    updated_at: '',
    updated_by: '',
    user_id: ''
}

const state: () => AuthState = () => ({
    logged: false,
    menus: [],
    profile: defaultProfile,
    session: defaultSession,
    user: {
        id: '',
        email: ''
    }
});

const isExpired = (expires_at: number) => {
    const now = new Date().getTime()
    return expires_at < (now / 1000)
}

const getters = {
    isLogged: (state: AuthState) => state.logged,
    isTokenExpired(state: AuthState) {
        const now = new Date().getTime()
        return state.session.expires_at > now
    },
}

const mutations = {
    setLogged(state: AuthState, payload: {logged: boolean}) { 
        state.logged = payload.logged;
    },
    setProfile(state: AuthState, payload: {profile: IProfile}) { 
        state.profile = payload.profile;
    },
    setUser(state: AuthState, payload: {user: IUser}) {
        state.user = payload.user;
    },
    setSession(state: AuthState, payload: {session: ISession, stayConnected: boolean}) {
        state.session = payload.session;
        state.session.stay_connected = payload.stayConnected;
    }
};

const actions = {
    async login({ commit, dispatch }: ActionContext<AuthState, any>, payload: ILogin) {
        try {
            const loginResponse = await supabase.auth.signInWithPassword({
                email: payload.email,
                password: payload.password
            })

            const user = { email: loginResponse.data.user?.email, id: loginResponse.data.user?.id }

            await commit('setSession', {session: loginResponse.data.session, stayConnected: false})
            await commit('setLogged', {logged: true})
            await commit('setUser', user)

            localStorage.setItem(LOCAL_STORAGE_ITEM, JSON.stringify({session: loginResponse.data.session, user: user}))

            dispatch('getProfile', {user_id: loginResponse.data.user?.id})
            dispatch('menu/getMenus', {}, {root: true})
            dispatch('menu/openMenu', {menu: {link: '/dashboard'}}, {root: true})
        } catch(e) {
            notifyError('Erro ao realizar o login');
        }
    },
    async getProfile({ commit }: ActionContext<AuthState, any>, payload: {user_id: string}) {
        try {
            const resultProfile = await supabase.from('profiles').select('*').eq('user_id', payload.user_id).single()
            commit('setProfile', { profile: resultProfile.data })
        } catch(e) {
            notifyError('Erro ao recuperar o perfil do usuário');
        }
    },
    async recoverToken({ commit, dispatch }: ActionContext<AuthState, any>) {
        const session = localStorage.getItem(LOCAL_STORAGE_ITEM)
        if (session) {
            const sessionData : {
                session: ISession,
                user: IUser
            }  = JSON.parse(session)
            
            if (isExpired(sessionData.session.expires_at)) {
                if (sessionData.session.stay_connected) {
                    await dispatch('refreshToken', { refreshToken: sessionData.session.refresh_token })
                } else {
                    dispatch('unauthorized')
                }
            } else {
                await commit('setSession', {session: defaultSession, stayConnected: false})
                await commit('setLogged', {logged: true})
                dispatch('getProfile', {user_id: sessionData.user.id})
                dispatch('menu/getMenus', {}, {root: true})
            }
        }
    },
    async unauthorized({ commit }: ActionContext<AuthState, any>, payload: {user_id: string}){
        localStorage.removeItem(LOCAL_STORAGE_ITEM)
        commit('setLogged', {logged: false})
        commit('setProfile', {profile: defaultProfile})
        commit('setUser', {user: {id: '', email: ''}})
        commit('setSession', {session: defaultSession, stayConnected: false})
        router.push({ name: 'login' })
    }
};

export default {
    namespaced: true,
    actions,
    mutations,
    getters,
    state,
};
