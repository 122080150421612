import { RouteRecordRaw } from 'vue-router';

const emplyeeRoutes : Array<RouteRecordRaw> = [
    {
        path: '/employee',
        name: 'employee',
        component: () => import(/* webpackChunkName: "about" */ './views/EmployeeView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/employee/:id',
        name: 'employeeDetails',
        component: () => import('./views/EmployeeDetailsView.vue'),
        props: true,
        meta: {
            requiresAuth: true
        }
    }
]

export default emplyeeRoutes;