import { RouteRecordRaw } from 'vue-router';

const clientsServicesSchedulesRoutes: Array<RouteRecordRaw> = [
    {
        path: '/services_schedule',
        name: '/services_schedule',
        component: () => import('./views/ClientsServicesScheduleView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/services_schedule/:date',
        name: 'clientsServicesSchedule',
        component: () => import('./views/ClientsServicesScheduleDayView.vue'),
        props: true,
        meta: {
            requiresAuth: true
        }
    }

]

export default clientsServicesSchedulesRoutes;