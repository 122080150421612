import { RouteRecordRaw } from 'vue-router';

const authRoutes : Array<RouteRecordRaw> = [
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "about" */ './views/LoginView.vue')
    }
]

export default authRoutes;