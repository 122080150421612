
import { toast, type ToastOptions } from 'vue3-toastify';


export function useNotification() {
  const notifySuccess = (message: string) => {
    console.log('notifySuccess', message);
    toast.success(message, { theme: "colored", position: "top-center" });
  };

  const notifyError = (message: string) => {
    toast.error(message,  { theme: "colored", position: "top-center" });
  };

  const notifyInfo = (message: string) => {
    toast.info(message,  { theme: "colored", position: "top-center" });
  };

  const notifyWarning = (message: string) => {
    toast.warn(message,  { theme: "colored", position: "top-center" });
  };

  return {
    notifySuccess,
    notifyError,
    notifyInfo,
    notifyWarning,
  };
}
