import { ActionContext } from 'vuex';
import { API_URL } from '@/config/api';
import { MenuState, IMenus } from '../types/menu.types'
import axiosAuth from '@/config/axios';
import router from '@/router';

const state: () => MenuState = () => ({
    mini: false,
    path: '',
    userMenus: [],
});

const mutations = {
    setPath: (state: MenuState, payload: {path: string}) => (state.path = payload.path),
    setMenuMini(state: MenuState, payload: {mini: boolean}) {
        state.mini = payload.mini;
    },
    setMenus(state: MenuState, payload: {menus: IMenus[]}) {
        state.userMenus = payload.menus
    },
};

const actions = {
    async getMenus({ commit }: ActionContext<MenuState, any>) {
        try {
            const response = await axiosAuth.get(`${API_URL}/menu/menus`);
            commit('setMenus', { menus: response.data });
        } catch(e) {
            console.log(e);
        }
    },
    async openMenu({ commit, state }: ActionContext<MenuState, any>, payload: {menu: IMenus}) {
        try {
            if (payload.menu.link) {
                if (payload.menu.link != state.path) {
                  commit("setPath", { path: payload.menu.link });
                  commit("setMenuMini", { mini: true });
                  router.push(payload.menu.link);
                }
            }
        } catch(e) {
            console.log(e);
        }
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
