import { ActionContext } from 'vuex';
import axiosAuth from '@/config/axios';
import { API_URL } from '@/config/api';
import { useNotification } from '@/composables/useNotification';
import { ClientsServicesSchedulesState } from '../types/state';
import { ClientsServicesSchedules } from '../types/clientsServiceSchedules';
import router from '@/router';
import { DefaultResponse } from '@/types/response';


const { notifySuccess, notifyError } = useNotification();

const state: () => ClientsServicesSchedulesState = () => ({
    servicesSchedules: [],
    pagination: {
        page: 1,
        itemsPerPage: 10,
        total: 0,
    },


});


const mutations = {

    SET_SERVICES_SCHEDULES(state: ClientsServicesSchedulesState, servicesSchedules: ClientsServicesSchedules[]) {
        state.servicesSchedules = servicesSchedules;
    },




};

const actions = {

    async fetchServicesSchedules({ commit }: ActionContext<ClientsServicesSchedulesState, any>, payload: { date: string }) {
        try {
            const response = await axiosAuth.get<DefaultResponse<ClientsServicesSchedules>>(`${API_URL}/clients_services_schedules/day?date=${payload.date}`);
            commit('SET_SERVICES_SCHEDULES', Object.values(response.data.data));
            console.log(Object.values(response.data.data))
        } catch (error) {
            notifyError('Erro ao buscar serviços agendados');
            console.error('Erro ao buscar serviços agendados:', error);
        }
    },



};



export default {
    namespaced: true,
    state,
    mutations,
    actions
};
