import { FormMode } from "@/consts/form";
import { StockControlState } from "../types/state";
import { ActionContext } from "vuex";
import axiosAuth from "@/config/axios";
import { DefaultResponse } from "@/types/response";
import { endPoints } from "@/config/api";
import { useNotification } from "@/composables/useNotification";
import { IFilterStockControl, StockControlItem } from "../types/stock_control";

const { notifySuccess, notifyError } = useNotification();

const defaultStockControlItem: StockControlItem = {
    description: '',
    unit: 'UN',
    createdAt: new Date(),
    createdBy: '',
    updatedAt: null,
    updatedBy: null
};

const defaultStockControlFilter: IFilterStockControl = {
    clientId: '',
    minQuantity: undefined,
    stockItemId: ''
};

const state: () => StockControlState = () => ({
    filterStockControl: Object.assign({}, defaultStockControlFilter),
    stockControlItems: [],
    pagination: {
        page: 1,
        itemsPerPage: 10,
        total: 0
    },
    selectedStockControlItem: Object.assign({}, defaultStockControlItem),
    formMode: FormMode.ADD,
    showFilterDialog: false,
    showFormDialog: false,
    showDeleteDialog: false,
});

const mutations = {
    SET_FILTER(state: StockControlState, payload: { filter: IFilterStockControl }) {
        state.filterStockControl = payload.filter
    },
    SHOW_DELETE_DIALOG(state: StockControlState, payload: { show: boolean }) {
        state.showDeleteDialog = payload.show;
    },
    SHOW_FILTER_DIALOG(state: StockControlState, payload: { show: boolean }) {
        console.log('vim aquii')
        state.showFilterDialog = payload.show;
    },
    SET_STOCK_CONTROL_ITEMS(state: StockControlState, payload: { stockControlItems: StockControlItem[] }) {
        state.stockControlItems = payload.stockControlItems;
    },
    SHOW_FORM_DIALOG(state: StockControlState, payload: { show: boolean }) {
        console.log('to aqui', payload)
        state.showFormDialog = payload.show;
    },
    SET_SELECTED_STOCK_CONTROL_ITEM(state: StockControlState, payload: { stockControlItem: StockControlItem }) {
        state.selectedStockControlItem = Object.assign({}, payload.stockControlItem);
    },
    SET_FORM_MODE(state: StockControlState, payload: { mode: FormMode }) {
        state.formMode = payload.mode;
    },
};

const actions = {
    closeDialog({ commit }: ActionContext<StockControlState, any>) {
        commit('SHOW_FORM_DIALOG', { show: false });
    },
    closeFilterDialog({ commit }: ActionContext<StockControlState, any>) {
        commit('SHOW_FILTER_DIALOG', { show: false });
    },
    openCreateDialog({ commit }: ActionContext<StockControlState, any>) {
        commit('SET_FORM_MODE', { mode: FormMode.ADD });
        commit('SET_SELECTED_STOCK_CONTROL_ITEM', { stockControlItem: defaultStockControlItem });
        commit('SHOW_FORM_DIALOG', { show: true });
      },
    openEditDialog({ commit }: ActionContext<StockControlState, any>, payload: { stockControlItem: StockControlItem }) {
        commit('SET_FORM_MODE', { mode: FormMode.EDIT });
        commit('SET_SELECTED_STOCK_CONTROL_ITEM', payload);
        commit('SHOW_FORM_DIALOG', { show: true });
    },
    openViewDialog({ commit }: ActionContext<StockControlState, any>,  payload: { stockControlItem: StockControlItem }) {
        commit('SET_FORM_MODE', { mode: FormMode.VIEW });
        commit('SET_SELECTED_STOCK_CONTROL_ITEM', payload);
        commit('SHOW_FORM_DIALOG', { show: true });
    },
    openDeleteDialog({ commit }: ActionContext<StockControlState, any>, payload: { stockControlItem: StockControlItem }) {
        commit('SHOW_DELETE_DIALOG', { show: true });
        commit('SET_SELECTED_STOCK_CONTROL_ITEM', { stockControlItem: payload.stockControlItem });
    },
    async fetchStockControls({ commit, state }: ActionContext<StockControlState, any>, ) {
        try {
            const params: any = {};
           
            for(const key in state.filterStockControl) {
                const value = state.filterStockControl[key as keyof typeof state.filterStockControl];
                if (value) {
                    params[key] = value;
                }
            }
            
            const response = await axiosAuth.get<DefaultResponse<StockControlItem>>(`${endPoints.stock_control}`, { params });
            commit('SET_STOCK_CONTROL_ITEMS', { stock_items: response.data.data });
        } catch (error) {
            notifyError('Erro ao buscar itens do estoque');
        }
    },
    async createStockControl({ commit, dispatch, state }: ActionContext<StockControlState, any>) {
        try {
          await axiosAuth.post(`${endPoints.stock_control}`, state.selectedStockControlItem);
          notifySuccess('Serviço criado com sucesso');
          commit('SHOW_FORM_DIALOG', { show: false });
          dispatch('fetchStockControls');
        } catch (error) {
          notifyError('Erro ao criar item do estoque');
        }
    },
    async updateStockControl({ commit, dispatch, state }: ActionContext<StockControlState, any>) {
        const { id } = state.selectedStockControlItem;
        try {
          await axiosAuth.put(`${endPoints.stock_control}/${id}`, state.selectedStockControlItem);
          notifySuccess('Serviço atualizado com sucesso');
          commit('SHOW_FORM_DIALOG', { show: false });
          dispatch('fetchStockControls');
        } catch (error) {
          notifyError('Erro');
          console.error('Erro ao atualizar item do estoque:', error);
        }
    },
    async deleteStockControl({ commit, dispatch }: ActionContext<StockControlState, any>, payload: {stock_items: StockControlItem}) {
        try {
          await axiosAuth.delete(`${endPoints.stock_control}/${payload.stock_items.id}`);
          notifySuccess('Serviço deletado com sucesso');
          commit('SHOW_DELETE_DIALOG', { show: false });
          dispatch('fetchStockControls');
        } catch (error) {
          notifyError('Erro');
          console.error('Erro ao deletar item do estoque:', error);
        }
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};