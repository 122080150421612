<template>
  
    <v-list-item
        link
        :key="menu.id"
        class="text-left"
        @click="openMenu({menu})"
    >
      <template v-slot:prepend v-if="menu.icon">
        <v-icon :icon="menu.icon"></v-icon>
      </template>
      {{ menu.description }}
    </v-list-item>
</template>

<script>

import { mapActions } from 'vuex'

export default {
  name: "SimpleMenuItem",
  computed: {
  },
  data: () => ({
    mini: true
  }),
  methods: {
    ...mapActions('menu', ['openMenu']),   
  },
  props: [
    'key_menu',
    'menu'
  ]
};
</script>

<style>
    .menu-line {
        display: inline;
    }
</style>