import { RouteRecordRaw } from 'vue-router';
import ClientDetailsView from './views/ClientDetailsView.vue';
import ClientDetails from './views/ClientDetails.vue';

const clientRoutes : Array<RouteRecordRaw> = [
    {
        path: '/client',
        name: 'client',
        component: () => import('./views/ClientView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/client/:id',
        name: 'clientDetails',
        component: () => import('./views/ClientDetailsView.vue'),
        props: true,
        meta: {
            requiresAuth: true
        }
    }
]

export default clientRoutes;