<template>
  <v-navigation-drawer v-model="drawer" @click="setMini(false)" class="elevation-10" :rail="mini" permanent >
    <v-list-item
      prepend-avatar="https://randomuser.me/api/portraits/men/85.jpg"
      :title="profile.name"
    >
      <template v-slot:append>
        <v-btn
          icon="mdi-chevron-left"
          variant="text"
          @click.stop="setMini(true)"
        ></v-btn>
      </template>
      <v-card-subtitle>
        {{ profile?.profile_access }}
        {{ profile?.email }}
        <v-spacer></v-spacer>
      </v-card-subtitle>
    </v-list-item>
    <v-card v-if="!mini" >
      
      <v-expand-transition>
        <div v-show="show">
          <v-card-actions class="text-center">
            <v-btn @click="logout()" outlined large>
                <v-icon>mdi-exit-to-app</v-icon>
            </v-btn>
          </v-card-actions>
        </div>
      </v-expand-transition>
      
    </v-card>
    <v-divider></v-divider>

    <v-list dense>
      <div 
        v-for="menu in menus"
        :key="menu.description"
      >
        <menu-item :menu="menu"></menu-item>
      </div>
    </v-list>

    <template v-slot:append>
      <div v-if="!mini" class="text-center">
        
        <v-btn color="primaryButton" class="mr-2" @click="logout()" outlined large>
            Sair
            <template v-slot:append>
              <v-icon color="white">mdi-exit-to-app</v-icon>
            </template>
        </v-btn>
      </div>
    </template>

  </v-navigation-drawer>
</template>
  
<script>
  
import { useStore } from 'vuex'
import MenuItem from './MenuItem'
import { computed, defineComponent, ref } from 'vue';

export default defineComponent({
  name: "MenuSystem",
  components: {
    "menu-item": MenuItem
  },
  setup() {
    const store = useStore();

    const profile = computed(() => store.state.auth.profile);
    const menus = computed(() => store.state.menu.userMenus);
    const logout = () => store.dispatch('auth/unauthorized');
    console.log("Menus: ", menus.value)
    const mini = ref(false);
    const drawer = true;
    const setMini = (value) => {
      mini.value = value
      console.log('trocando', value, mini)
    }

    return {
      drawer,
      logout,
      menus,
      mini,
      profile,
      setMini
    }
  }
})
</script>
  
<style lang="sass">
  
  .v-navigation-drawer__content::-webkit-scrollbar-thumb 
    border-radius: 6px
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3)
    background-color: #e4dcd4

  .v-navigation-drawer__content::-webkit-scrollbar 
    width: 8px
    background-color: #e4dcd4

  .v-navigation-drawer__content::-webkit-scrollbar-thumb 
    border-radius: 6px
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3)
    background-color: #e4dcd4
  
</style>